import React from 'react'

import Footer from './Footer'
import avatar from '../assets/images/website-avatar.png'

class Header extends React.Component {
  render() {
    return (
      <header id="header">
        <div className="inner">
          <div className="justify-align-center">
            <div className="image avatar"><img src={avatar} alt="" /></div>
          </div>
          <h1 align="center"><strong>Patrick Brown Jr.</strong>
            <span style={{display: "block"}}>Software Engineer</span>
          </h1>
        </div>
        <Footer />
      </header>
    )
  }
}

export default Header
